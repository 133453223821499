import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import styled from "styled-components";
import PersonalitySnapshot from "PersonalitySnapshot";
import { AvatarURL } from "Stories/Avatars.stories.js";
import CurrentModal from "./CurrentModal";
import DesiresModal from "./DesiresModal";
import PitchModal from "./PitchModal";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class ProfileScreen extends Component {
  state = {
    avatar: {
      slug: "",
    },
    anonymousUsername: "",
    displayModal: "",
    userDesiredIndustry: "",
    userDesiredCompanyType: "",
    userDesiredJobPositionSpecialties: [],
    userDesiredJobLevel: "",
    desiredAnnualSalaryMin: 0,
    desiredAnnualSalaryMax: 0,
    remoteOk: false,
    jobSearchStage: {},
    currentJobPosition: {},
    currentJobLevel: {},
    currentCompanyType: {},
    currentDegree: {},
    currentIndustry: {},
    currentAnnualSalaryMin: 0,
    currentAnnualSalaryMax: 0,
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "jobSearchStage", "currentJobPosition", "currentJobLevel", "currentCompanyType", "currentDegree", "currentIndustry"]
      }`
    ).then((response) => this.setState(response.data));
  }

  editModal = (modalName) => {
    this.setState({
      displayModal: modalName,
    });
  };

  getFreshData = () => {
    console.log("Getting Fresh Data...");
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": ["avatar", "userDesiredIndustry", "userDesiredCompanyType", "userDesiredJobLevel", "jobSearchStage", "currentJobPosition", "currentJobLevel", "currentCompanyType", "currentDegree", "currentIndustry"]
      }`
    ).then((response) => {
      console.log(response.data);
      this.setState(response.data);
    });
  };

  closeModal = () => {
    this.setState({
      displayModal: "",
    });
  };

  render() {
    let avatarURL = require("../" + AvatarURL(this.state.avatar.slug));
    return (
      <Container>
        <NavBarRecruiterWithItems {...this.props} />
        <Row>
          <Col md={2}>
            <InfoCardContainer>
              <InfoCardIcon src={require("images/icons/Thinking Style.svg")} />
              <InfoCardMessage>
                This is how candidates will view your profile. Please ensure
                that all the information here is filled in and up to date!
              </InfoCardMessage>
            </InfoCardContainer>
          </Col>
          <Col>
            <ProfileContainer>
              <ProfileHeadingContainer>
                <EditBar>
                  <EditTitle onClick={() => this.editModal("pitch")}>
                    Edit
                  </EditTitle>
                </EditBar>
                <ProfileHeadingIcon src={avatarURL} />
                <ProfileHeadingTitle>
                  GIGAVOLT User {this.state.anonymousUsername}
                </ProfileHeadingTitle>
                <SearchIcon
                  style={{
                    marginBottom: 10,
                  }}
                />
                <ProfileHeadingPitch>
                  {this.state.userPitch}
                </ProfileHeadingPitch>
              </ProfileHeadingContainer>
              <ProfileSummaryContainer>
                <EditBar>
                  <EditTitle onClick={() => this.editModal("desires")}>
                    Edit
                  </EditTitle>
                </EditBar>
                <ProfileSummarySectionContainer bg="mountain">
                  <ProfileSummarySectionHeading>
                    Where I Want To Be
                  </ProfileSummarySectionHeading>
                  <ProfileSummarySectionDescription>
                    Please describe your dream or ideal work. This could be
                    anything from your 1-year goals to 10-year goals. With this
                    information, we can help you find opportunties that you
                    never knew you could qualify for.
                  </ProfileSummarySectionDescription>
                  <ProfileSummarySectionItemsContainer>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Desired Industry
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.userDesiredIndustry.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Desired Company Type
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.userDesiredCompanyType.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Desired Job Position Specialties
                      </ProfileSummarySectionItemTitle>
                      {this.state.userDesiredJobPositionSpecialties.map(
                        (jobPositionSpecialty) => (
                          <ProfileSummarySectionItemData>
                            {jobPositionSpecialty.jobPositionSpecialty.name +
                              " ( " +
                              jobPositionSpecialty.experience +
                              " Years )"}
                          </ProfileSummarySectionItemData>
                        )
                      )}
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Desired Job Level
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.userDesiredJobLevel.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    {/* <SliderContainer>
                      <ProfileSummarySectionItemTitle>
                        Desired Annual Salary
                      </ProfileSummarySectionItemTitle>
                      <Range
                        min={0}
                        max={200000}
                        marks={{
                          0: "$0",
                          25000: "$25,000",
                          50000: "$50,000",
                          75000: "$75,000",
                          100000: "$100,000",
                          125000: "$125,000",
                          150000: "$150,000",
                          175000: "$175,000",
                          200000: "$200,000+",
                        }}
                        value={[
                          this.state.desiredAnnualSalaryMin,
                          this.state.desiredAnnualSalaryMax,
                        ]}
                        defaultValue={[
                          this.state.desiredAnnualSalaryMin,
                          this.state.desiredAnnualSalaryMax,
                        ]}
                        tipFormatter={(value) => `${"$" + value}`}
                        railStyle={{
                          backgroundColor: "#f5f5f5",
                        }}
                        trackStyle={[
                          { backgroundColor: "#7D59FC" },
                          { backgroundColor: "#7D59FC" },
                        ]}
                        handleStyle={[
                          {
                            backgroundColor: "#7D59FC",
                            borderColor: "#7D59FC",
                            width: 8,
                            height: 8,
                            top: 7.5,
                          },
                          {
                            backgroundColor: "#7D59FC",
                            borderColor: "#7D59FC",
                            width: 8,
                            height: 8,
                            top: 7.5,
                          },
                        ]}
                        dotStyle={{
                          backgroundColor: "#f5f5f5",
                        }}
                        activeDotStyle={{
                          backgroundColor: "#7D59FC",
                          borderColor: "#7D59FC",
                        }}
                      />
                    </SliderContainer> */}
                    {/* <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Willing To Work Remotely
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.remoteOk ? "Yes" : "No"}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem> */}
                  </ProfileSummarySectionItemsContainer>
                </ProfileSummarySectionContainer>
                <ProfileSummarySectionContainer bg="climber">
                  <EditBar>
                    <EditTitle onClick={() => this.editModal("current")}>
                      Edit
                    </EditTitle>
                  </EditBar>
                  <ProfileSummarySectionHeading>
                    What I'm Doing Right Now
                  </ProfileSummarySectionHeading>
                  <ProfileSummarySectionDescription>
                    What are you currently doing? What unique experiences and
                    qualifications do you currently have? Please describe your
                    current status here.
                  </ProfileSummarySectionDescription>
                  <ProfileSummarySectionItemsContainer>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Current Job Search Stage
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.jobSearchStage.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Current Position Title
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.currentJobPosition.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Current Job Level
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.currentJobLevel.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Current Company Type
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.currentCompanyType.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Most Recent Industry
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.currentIndustry.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                    <SliderContainer>
                      <ProfileSummarySectionItemTitle>
                        Current Annual Salary
                      </ProfileSummarySectionItemTitle>
                      <Range
                        min={0}
                        max={200000}
                        marks={{
                          0: "$0",
                          25000: "$25,000",
                          50000: "$50,000",
                          75000: "$75,000",
                          100000: "$100,000",
                          125000: "$125,000",
                          150000: "$150,000",
                          175000: "$175,000",
                          200000: "$200,000+",
                        }}
                        value={[
                          this.state.currentAnnualSalaryMin,
                          this.state.currentAnnualSalaryMax,
                        ]}
                        defaultValue={[
                          this.state.currentAnnualSalaryMin,
                          this.state.currentAnnualSalaryMax,
                        ]}
                        tipFormatter={(value) => `${"$" + value}`}
                        railStyle={{
                          backgroundColor: "#f5f5f5",
                        }}
                        trackStyle={[
                          { backgroundColor: "#7D59FC" },
                          { backgroundColor: "#7D59FC" },
                        ]}
                        handleStyle={[
                          {
                            backgroundColor: "#7D59FC",
                            borderColor: "#7D59FC",
                            width: 8,
                            height: 8,
                            top: 7.5,
                          },
                          {
                            backgroundColor: "#7D59FC",
                            borderColor: "#7D59FC",
                            width: 8,
                            height: 8,
                            top: 7.5,
                          },
                        ]}
                        dotStyle={{
                          backgroundColor: "#f5f5f5",
                        }}
                        activeDotStyle={{
                          backgroundColor: "#7D59FC",
                          borderColor: "#7D59FC",
                        }}
                      />
                    </SliderContainer>

                    <ProfileSummarySectionItem>
                      <ProfileSummarySectionItemTitle>
                        Education
                      </ProfileSummarySectionItemTitle>
                      <ProfileSummarySectionItemData>
                        {this.state.currentDegree.name}
                      </ProfileSummarySectionItemData>
                    </ProfileSummarySectionItem>
                  </ProfileSummarySectionItemsContainer>
                </ProfileSummarySectionContainer>
              </ProfileSummaryContainer>
            </ProfileContainer>
          </Col>
          <Col md={3}>
            <PersonalitySnapshot {...this.props} />
          </Col>
        </Row>
        <PitchModal
          getFreshData={this.getFreshData}
          closeModal={this.closeModal}
          displayModal={this.state.displayModal}
          {...this.props}
        />
        <CurrentModal
          getFreshData={this.getFreshData}
          closeModal={this.closeModal}
          displayModal={this.state.displayModal}
          {...this.props}
        />
        <DesiresModal
          getFreshData={this.getFreshData}
          closeModal={this.closeModal}
          displayModal={this.state.displayModal}
          {...this.props}
        />
      </Container>
    );
  }
}

export default ProfileScreen;

const InfoCardContainer = styled.div`
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  border-top: 5px solid #7D59FC;
`;
const InfoCardIcon = styled.img`
  margin-bottom: 20px;
`;
const InfoCardMessage = styled.p`
  font-size: 12px;
  text-align: center;
  color: #999;
`;

const ProfileContainer = styled.div`
  margin-top: 20px;
`;
const ProfileHeadingContainer = styled.div`
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-top: 5px solid #7D59FC;
`;
const ProfileHeadingIcon = styled.img``;
const ProfileHeadingTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
`;
const ProfileHeadingPitch = styled.p`
  text-align: center;
  color: #999;
  font-size: 14px;
`;
const ProfileSummaryContainer = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;
const ProfileSummarySectionContainer = styled.div`
  background-image: ${(props) =>
    props.bg === "mountain"
      ? `url("/images/img-mountain.svg");`
      : `url("/images/img-climber.svg");`}
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

const ProfileSummarySectionHeading = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #7D59FC;
`;
const ProfileSummarySectionDescription = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;

const ProfileSummarySectionItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ProfileSummarySectionItem = styled.div`
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 4px;
  padding: 20px;
`;
const ProfileSummarySectionItemTitle = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;
const ProfileSummarySectionItemData = styled.p`
  margin: 0;
  color: #999;
  font-size: 14px;
`;

const EditBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;
const EditTitle = styled.p`
  cursor: pointer;
  color: #999;
  :hover {
    color: red;
  }
`;

const SliderContainer = styled.div`
  width: 97%;
  padding: 20px;
`;
