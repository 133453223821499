import React, { Component } from "react";
import styled from "styled-components";
import API from "API";
import { Link } from "react-router-dom";
import { titleCase } from "title-case";
import { ReactComponent as LockIcon } from "images/icons/Lock.svg";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import { BrowserView, MobileOnlyView } from "react-device-detect";

class PersonalitySnapshot extends Component {
  state = {
    profileComplete: false,
    writingSample: {
      receptiviti: {
        receptiviti_scores: {
          percentiles: [],
        },
      },
    },
    topScores: [],
    writingSampleText: "",
    modalVisible: false,
    writingSampleTypes: [],
    writingSampleTypeId: "",
    user: {
      anonymousUsername: "",
    },
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": "userWritingSample"
      }`
    ).then((response) => {
      console.log(response.data);
      if ("userWritingSample" in response.data) {
        let receptivitiScores =
          response.data.userWritingSample.receptiviti.receptiviti_scores
            .percentiles;

        let entries = Object.entries(receptivitiScores).sort(
          (a, b) => b[1] - a[1]
        );

        entries = entries.filter((entry) => {
          if (
            entry[0] !== "sexual_focus" &&
            entry[0] !== "conscientiousness" &&
            entry[0] !== "trusting" &&
            entry[0] !== "dutiful" &&
            entry[0] !== "food_focus" &&
            entry[0] !== "religion_oriented" &&
            entry[0] !== "body_focus" &&
            entry[0] !== "friend_focus"
          )
            return entry;
        });

        let userTopScores = [];

        for (var i = 0; i < 5; i++) {
          let obj = {
            name: [entries[i][0]],
            value: entries[i][1],
          };
          userTopScores.push(obj);
        }

        this.setState({
          user: response.data,
          profileComplete: response.data.profileComplete,
          writingSample: response.data.userWritingSample,
          writingSampleText: response.data.userWritingSample.writingSample,
          topScores: userTopScores,
        });
      }
    });

    API.get("/WritingSampleTypes").then((response) => {
      this.setState({
        writingSampleTypes: response.data,
      });
    });

    // this.setState({
    //   myInt: myInt,
    // });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // API.get(
    //   `/UserProfiles/${this.props.userToken.userId}?filter={
    //     "include": "userWritingSample"
    //   }`
    // ).then(response => {
    //   console.log(response.data);
    //   if ("userWritingSample" in response.data) {
    //     let receptivitiScores =
    //       response.data.userWritingSample.receptiviti.receptiviti_scores
    //         .percentiles;
    //     let entries = Object.entries(receptivitiScores).sort(
    //       (a, b) => b[1] - a[1]
    //     );
    //     let userTopScores = [];
    //     for (var i = 0; i < 5; i++) {
    //       let obj = {
    //         name: [entries[i][0]],
    //         value: entries[i][1]
    //       };
    //       userTopScores.push(obj);
    //     }
    //     this.setState({
    //       user: response.data,
    //       profileComplete: response.data.profileComplete,
    //       writingSample: response.data.userWritingSample,
    //       writingSampleText: response.data.userWritingSample.writingSample,
    //       topScores: userTopScores
    //     });
    //   }
    // });
    // API.get("/WritingSampleTypes").then(response => {
    //   this.setState({
    //     writingSampleTypes: response.data
    //   });
    // });
    // API.get(
    //   `/UserProfiles/${this.props.userToken.userId}?filter={
    //   "include": "userWritingSample"
    // }`
    // ).then((response) => {
    //   if ("userWritingSample" in response.data) {
    //     let receptivitiScores =
    //       response.data.userWritingSample.receptiviti.receptiviti_scores
    //         .percentiles;
    //     let entries = Object.entries(receptivitiScores).sort(
    //       (a, b) => b[1] - a[1]
    //     );
    //     let userTopScores = [];
    //     for (var i = 0; i < 5; i++) {
    //       let obj = {
    //         name: [entries[i][0]],
    //         value: entries[i][1],
    //       };
    //       userTopScores.push(obj);
    //     }
    //     this.setState({
    //       profileComplete: response.data.profileComplete,
    //       writingSample: response.data.userWritingSample,
    //       topScores: userTopScores,
    //     });
    //   }
    // });
  }

  componentWillUnmount() {
    // clearInterval(this.state.myInt)
  }

  handleSubmit = (e) => {
    API.post(`/UserWritingSamples`, {
      writingSample: this.state.writingSampleText,
      writingSampleTypeId: this.state.writingSampleType,
      userProfileId: this.props.userToken.userId,
    }).then((response) => {
      let writingSampleId = response.data.id;
      API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        userWritingSampleId: writingSampleId,
      }).then(() => {
        this.setState({
          modalVisible: false,
        });
        API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": "userWritingSample"
        }`
        ).then((response) => {
          console.log(response.data);
          if ("userWritingSample" in response.data) {
            let receptivitiScores =
              response.data.userWritingSample.receptiviti.receptiviti_scores
                .percentiles;

            let entries = Object.entries(receptivitiScores).sort(
              (a, b) => b[1] - a[1]
            );

            let userTopScores = [];

            for (var i = 0; i < 5; i++) {
              let obj = {
                name: [entries[i][0]],
                value: entries[i][1],
              };
              userTopScores.push(obj);
            }

            this.setState({
              user: response.data,
              profileComplete: response.data.profileComplete,
              writingSample: response.data.userWritingSample,
              writingSampleText: response.data.userWritingSample.writingSample,
              topScores: userTopScores,
            });
          }
        });

        API.get("/WritingSampleTypes").then((response) => {
          this.setState({
            writingSampleTypes: response.data,
          });
        });
      });
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  showUpdateWritingSample = (e) => {
    this.setState({
      modalVisible: true,
    });
  };

  renderData = (score, i) => {
    // if (i % 2 === 0) {
    //   return (
    //     <DataContainerLeft>
    //       <DataContainerLeftTitle>Impulsive</DataContainerLeftTitle>
    //       <DataContainerLeftBarContainer>
    //         <DataContainerLeftBarPercent>92%</DataContainerLeftBarPercent>
    //       </DataContainerLeftBarContainer>
    //     </DataContainerLeft>
    //   );
    // } else {
    //   return (
    //     <DataContainerRight>
    //       <DataContainerRightBarContainer>
    //         <DataContainerRightBarPercent>90%</DataContainerRightBarPercent>
    //       </DataContainerRightBarContainer>
    //       <DataContainerRightTitle>Impulsive</DataContainerRightTitle>
    //     </DataContainerRight>
    //   );
    // }

    let entries = Object.entries(
      this.state.writingSample.receptiviti.receptiviti_scores.percentiles
    );

    let sortedEntries = entries.sort((a, b) => {
      return b[1] - a[1];
    });

    let filteredEntries = sortedEntries.filter((entry) => {
      if (
        entry[0] !== "sexual_focus" &&
        entry[0] !== "conscientiousness" &&
        entry[0] !== "trusting" &&
        entry[0] !== "dutiful" &&
        entry[0] !== "food_focus" &&
        entry[0] !== "religion_oriented" &&
        entry[0] !== "body_focus" &&
        entry[0] !== "friend_focus"
      )
        return entry;
    });

    // this.setState({
    //   topScores: filteredEntries
    // })

    let greaterThan50 = filteredEntries.filter((entry) => entry[1] >= 50);
    let lessThan50 = filteredEntries.filter((entry) => entry[1] < 50);

    let tornado = [];
    let bottomTornado = [];
    for (var i = 0; i <= greaterThan50.length; i = i + 2) {
      try {
        tornado.push(
          <DataWrapper>
            <DataContainerLeft>
              <DataContainerLeftTitle>
                {titleCase(greaterThan50[i][0].replace("_", " "))}
              </DataContainerLeftTitle>
              <DataContainerLeftBarContainer percent={greaterThan50[i][1]}>
                <DataContainerLeftBarPercent>
                  {parseInt(greaterThan50[i][1])}%
                </DataContainerLeftBarPercent>
              </DataContainerLeftBarContainer>
            </DataContainerLeft>
            <DataContainerRight>
              <DataContainerRightBarContainer percent={greaterThan50[i + 1][1]}>
                <DataContainerRightBarPercent>
                  {parseInt(greaterThan50[i + 1][1])}%
                </DataContainerRightBarPercent>
              </DataContainerRightBarContainer>
              <DataContainerRightTitle>
                {titleCase(greaterThan50[i + 1][0].replace("_", " "))}
              </DataContainerRightTitle>
            </DataContainerRight>
          </DataWrapper>
        );
      } catch (e) {}
    }

    const midline = (
      <MidLineContainer>
        <MidLineText>50th Percentile</MidLineText>
        <MidLineSpacer />
      </MidLineContainer>
    );

    for (var i = 0; i <= lessThan50.length; i = i + 2) {
      try {
        bottomTornado.push(
          <DataWrapper>
            <DataContainerLeft>
              <DataContainerLeftTitle>
                {titleCase(lessThan50[i][0].replace("_", " "))}
              </DataContainerLeftTitle>
              <DataContainerLeftBarContainer percent={lessThan50[i][1]}>
                <DataContainerLeftBarPercent>
                  {parseInt(lessThan50[i][1])}%
                </DataContainerLeftBarPercent>
              </DataContainerLeftBarContainer>
            </DataContainerLeft>
            <DataContainerRight>
              <DataContainerRightBarContainer percent={lessThan50[i + 1][1]}>
                <DataContainerRightBarPercent>
                  {parseInt(lessThan50[i + 1][1])}%
                </DataContainerRightBarPercent>
              </DataContainerRightBarContainer>
              <DataContainerRightTitle>
                {titleCase(lessThan50[i + 1][0].replace("_", " "))}
              </DataContainerRightTitle>
            </DataContainerRight>
          </DataWrapper>
        );
      } catch (e) {}
    }
    return [...tornado, midline, ...bottomTornado];
  };

  render() {
    return this.state.profileComplete || this.props.showPersonality
      ? this.renderProfileCompleted()
      : this.renderProfileLocked();
  }

  renderProfileCompleted() {
    return (
      <PersonalitySnapshotContainer>
        <PersonalitySnapshotTitleContainer>
          <PersonalitySnapshotTitle>
            Communication Style Report
          </PersonalitySnapshotTitle>
        </PersonalitySnapshotTitleContainer>
        <PersonalityScoreBarsContainer>
          {this.state.topScores.map((topScore, index) => (
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={1 - (0.1 * index)}>
                <PersonalityScorePercentage>
                  {parseInt(topScore.value)}
                </PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>
                {titleCase(topScore.name.toString().replace("_", " "))}
              </PersonalityScoreTitle>
            </PeronsalityScoreBar>
          ))}
        </PersonalityScoreBarsContainer>
        <PersonalityDetailsContainer>
          {this.state.writingSample.receptiviti.personality_snapshot.map(
            (personalitySnapshot) => (
              <PersonalityDetail>
                <PersonalityDetailHeading>
                  {personalitySnapshot.summary}
                </PersonalityDetailHeading>
                <PersonalityDetailDescription>
                  {personalitySnapshot.description[0].content || personalitySnapshot.description}
                </PersonalityDetailDescription>
              </PersonalityDetail>
            )
          )}
        </PersonalityDetailsContainer>
        <BrowserView>
          <CultureReportContainer
            onClick={() => {
              this.setState({
                cultureReportModal: true,
              });
            }}
          >
            <CultureReportHeading>
              View Full Communication Style Report
            </CultureReportHeading>
          </CultureReportContainer>
        </BrowserView>
        <CultureReportContainer onClick={this.showUpdateWritingSample}>
          <CultureReportHeading>Update Writing Sample</CultureReportHeading>
        </CultureReportContainer>
        <Modal
          show={this.state.cultureReportModal}
          dialogClassName="modal-1300w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <CultureReportHeaderContainer>
            <CultureReportGoGigLogo
              src={require("images/logo/gigavolt-logo-1tone.png")}
            />
            <CultureReportTitle>
              GIGAVOLT User {this.state.user.anonymousUsername}'s
              Communication Style Report
            </CultureReportTitle>
            <CultureReportExit
              onClick={() => this.setState({ cultureReportModal: false })}
            >
              &#10005;
            </CultureReportExit>
          </CultureReportHeaderContainer>
          <FullCultureReportContainer>
            <CultureReportSnapshotContainer>
              <CultureReportSnapshotTitleContainer>
                <CultureReportCamera
                  src={require("images/icons/psnapshot.svg")}
                />
                <CultureReportSnapshotTitle>
                  Personality Snapshot
                </CultureReportSnapshotTitle>
              </CultureReportSnapshotTitleContainer>
              {this.state.writingSample.receptiviti.personality_snapshot.map(
                (snapshot) => (
                  <PersonalitySnapshotViewContainer>
                    <PersonalitySnapshotSubtitle>
                      {snapshot.summary}
                    </PersonalitySnapshotSubtitle>
                    <PersonalitySnapshotDescription>
                      {snapshot.description[0].content || snapshot.description}
                    </PersonalitySnapshotDescription>
                  </PersonalitySnapshotViewContainer>
                )
              )}
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  width: "100%",
                  height: 1,
                  paddingLeft: 80,
                  marginTop: 40,
                  marginBottom: 20,
                }}
              />
              <CultureReportSnapshotTitleContainer>
                <CultureReportCamera
                  src={require("images/icons/CommunicationRecommendation.svg")}
                />
                <CultureReportSnapshotTitle>
                  Communication Recommendation
                </CultureReportSnapshotTitle>
              </CultureReportSnapshotTitleContainer>
              <p style={{ paddingLeft: 60, fontSize: 14, color: "#999" }}>
                {
                  this.state.writingSample.receptiviti
                    .communication_recommendation
                }
              </p>
              <CultureReportSnapshotTitleContainer
                style={{
                  marginTop: 70,
                }}
              >
                <CultureReportCamera
                  src={require("images/icons/Thinking Style.svg")}
                />
                <CultureReportSnapshotTitle>
                  Thinking Style
                </CultureReportSnapshotTitle>
              </CultureReportSnapshotTitleContainer>
              <div
                style={{
                  paddingLeft: 60,
                }}
              >
                <Slider
                  defaultValue={
                    this.state.writingSample.receptiviti.liwc_scores.analytic
                  }
                  disabled={true}
                  min={0}
                  max={100}
                  marks={{
                    0: {
                      style: {
                        color: "#999 !important",
                        width: 150,
                        position: "relative",
                        left: 60,
                      },
                      label: "Emotional-based decision maker",
                    },
                    100: {
                      style: {
                        width: 200,
                        paddingRight: 100,
                        color: "#999 !important",
                      },
                      label: "Analytical decision maker",
                    },
                  }}
                  allowCross={false}
                  tipFormatter={(value) => `${value}`}
                  railStyle={{
                    backgroundColor: "#f5f5f5",
                    height: 10,
                  }}
                  trackStyle={[
                    { background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)", height: 10 },
                    { background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)", height: 10 },
                  ]}
                  handleStyle={[
                    {
                      background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)",
                      borderColor: "rgba(125, 89, 252, 0)",
                      width: 0,
                      height: 0,
                      top: 6,
                    },
                    {
                      background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)",
                      borderColor: "rgba(125, 89, 252, 0)",
                      width: 0,
                      height: 0,
                      top: 6,
                    },
                  ]}
                  dotStyle={{
                    display: "none",
                  }}
                />
              </div>

              <CultureReportSnapshotTitleContainer
                style={{
                  marginTop: 125,
                }}
              >
                <CultureReportCamera
                  src={require("images/icons/Authenticity Detector.svg")}
                />
                <CultureReportSnapshotTitle>
                  Authenticity Detector
                </CultureReportSnapshotTitle>
              </CultureReportSnapshotTitleContainer>
              <div
                style={{
                  paddingLeft: 60,
                }}
              >
                <Slider
                  defaultValue={
                    this.state.writingSample.receptiviti.liwc_scores.authentic
                  }
                  disabled={true}
                  min={0}
                  max={100}
                  marks={{
                    0: "Unlikely Authentic",
                    100: "Likely Authentic",
                  }}
                  allowCross={false}
                  tipFormatter={(value) => `${value}`}
                  railStyle={{
                    backgroundColor: "#f5f5f5",
                    height: 10,
                  }}
                  trackStyle={[
                    { background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)", height: 10 },
                    { background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)", height: 10 },
                  ]}
                  handleStyle={[
                    {
                      background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)",
                      borderColor: "rgba(125, 89, 252, 0)",
                      width: 0,
                      height: 0,
                      top: 6,
                    },
                    {
                      background: "linear-gradient(90deg, rgba(125, 89, 252, 1) 0%, rgba(254, 131, 42, 1) 100%)",
                      borderColor: "rgba(125, 89, 252, 0)",
                      width: 0,
                      height: 0,
                      top: 6,
                    },
                  ]}
                  dotStyle={{
                    display: "none",
                  }}
                />
              </div>
            </CultureReportSnapshotContainer>
            <CultureReportDataContainer>
              <CultureReportDataTitle>
                Natural Language Processing Scores
              </CultureReportDataTitle>

              <TopFiveTitle>Top 5</TopFiveTitle>
              <TopFiveBubblesContainer>
                <BubbleScoreContainer>
                  <BubbleContainer opacity={1} size={65}>
                    <BubbleText>
                      {parseInt(this.state.topScores[0].value)}%
                    </BubbleText>
                  </BubbleContainer>
                  <BubbleSubtitle>
                    {titleCase(
                      this.state.topScores[0].name[0].replace("_", " ")
                    )}
                  </BubbleSubtitle>
                </BubbleScoreContainer>
                <BubbleScoreContainer>
                  <BubbleContainer opacity={0.90} size={60}>
                    <BubbleText>
                      {parseInt(this.state.topScores[1].value)}%
                    </BubbleText>
                  </BubbleContainer>
                  <BubbleSubtitle>
                    {titleCase(
                      this.state.topScores[1].name[0].replace("_", " ")
                    )}
                  </BubbleSubtitle>
                </BubbleScoreContainer>
                <BubbleScoreContainer>
                  <BubbleContainer opacity={0.80} size={58}>
                    <BubbleText>
                      {parseInt(this.state.topScores[2].value)}%
                    </BubbleText>
                  </BubbleContainer>
                  <BubbleSubtitle>
                    {titleCase(
                      this.state.topScores[2].name[0].replace("_", " ")
                    )}
                  </BubbleSubtitle>
                </BubbleScoreContainer>
                <BubbleScoreContainer>
                  <BubbleContainer opacity={0.70} size={55}>
                    <BubbleText>
                      {parseInt(this.state.topScores[3].value)}%
                    </BubbleText>
                  </BubbleContainer>
                  <BubbleSubtitle>
                    {titleCase(
                      this.state.topScores[3].name[0].replace("_", " ")
                    )}
                  </BubbleSubtitle>
                </BubbleScoreContainer>
                <BubbleScoreContainer>
                  <BubbleContainer opacity={0.60} size={50}>
                    <BubbleText>
                      {parseInt(this.state.topScores[4].value)}%
                    </BubbleText>
                  </BubbleContainer>
                  <BubbleSubtitle>
                    {titleCase(
                      this.state.topScores[4].name[0].replace("_", " ")
                    )}
                  </BubbleSubtitle>
                </BubbleScoreContainer>
              </TopFiveBubblesContainer>
              <TopFiveTitle>All Scores</TopFiveTitle>
              <DataContainer>
                {/* {Object.entries(
                  this.state.writingSample.receptiviti.receptiviti_scores
                    .percentiles
                ).map((score, i) =>
                  this
                )} */}
                {this.renderData()}
              </DataContainer>
            </CultureReportDataContainer>
          </FullCultureReportContainer>
        </Modal>
        <Modal
          show={this.state.modalVisible}
          dialogClassName="modal-65w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <ModalContainerTwo>
            <ModalCol className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
              <img
                style={{
                  width: 450,
                  bottom: 0,
                }}
                src={require("images/modals/gogig-career-writing-sample.png")}
              />
            </ModalCol>
            <ModalCol className="bg-white p-5 w-100">
              <Row>
                <Col md={12}>
                  <h1>Communication Style Report</h1>
                  <p>
                    GIGAVOLT uses AI and Natural Language
                    Processing to better understand your communication style
                    through the analysis of a writing sample. Hiring
                    professionals using GIGAVOLT will
                    communicate with you upon outreach more effectively based on
                    your Communication Style Report metrics generated
                    here.
                  </p>
                  {/* <button class="loginBtn loginBtn--facebook">
                    Use My Facebook
                  </button> */}
                  <Form className="mt-3">
                    <Form.Row>
                      <Form.Label>
                        Please find a professional writing sample that you've
                        typed up that is at least 200 words or more and paste it
                        into the field below (you can update this section later
                        if you want):
                      </Form.Label>
                      <Form.Control
                        name="writingSampleType"
                        as="select"
                        onChange={this.handleChange}
                        value={this.state.writingSampleType}
                      >
                        <option value="" disabled>
                          Select a writing sample
                        </option>
                        {this.state.writingSampleTypes.map(
                          (writingSampleType) => (
                            <option value={writingSampleType.id}>
                              {writingSampleType.name}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Form.Control
                        className="p-3"
                        style={{
                          backgroundColor: "#F5F9FB",
                        }}
                        name="writingSampleText"
                        onChange={this.handleChange}
                        as="textarea"
                        rows="15"
                        value={this.state.writingSampleText}
                        placeholder="The most common examples of professional writing samples that we suggest you use are: Cover Letter, Professional Email, Personal Bio, LinkedIn Bio, Memo, Professional Report, Commercial Writing Sample, or any other writing piece that you've typed in a professional setting."
                      />
                    </Form.Row>
                    <div>
                      <p
                        style={{
                          color: "#999",
                        }}
                      >
                        Characters Remaining:{" "}
                        {1400 - this.state.writingSampleText.length < 0
                          ? "Sufficient"
                          : 1400 - this.state.writingSampleText.length}
                      </p>
                    </div>
                    <Form.Row className="mt-2">
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          this.setState({
                            modalVisible: false,
                          });
                        }}
                      >
                        Exit
                      </Button>
                      <Button className="ml-auto" onClick={this.handleSubmit}>
                        Finish
                      </Button>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
      </PersonalitySnapshotContainer>
    );
  }

  renderProfileLocked() {
    return (
      <Overlay>
        <LockedMessageContainer>
          <StyledLockIcon />
          <LockedMessage>
            Your Communication Style Report section is locked because you have not
            finished setting up your profile.
          </LockedMessage>
          <Link to="/profile?onboard=true">
            <CompleteProfileLink
              onClick={() => {
                this.props.updateOnboard(true);
                this.props.editModal("current");
              }}
            >
              View Profile
            </CompleteProfileLink>
          </Link>
        </LockedMessageContainer>
        <PersonalitySnapshotContainer overlay={true}>
          <PersonalitySnapshotTitle>
            Communication Style Report
          </PersonalitySnapshotTitle>
          <PersonalityScoreBarsContainer>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={1}>
                <PersonalityScorePercentage>100</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Independent</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={0.9}>
                <PersonalityScorePercentage>95</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Generous</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={0.8}>
                <PersonalityScorePercentage>78</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Agreeable</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={0.7}>
                <PersonalityScorePercentage>85</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Empatehetic</PersonalityScoreTitle>
            </PeronsalityScoreBar>
            <PeronsalityScoreBar>
              <PersonalityScorePercentageContainer opacity={0.6}>
                <PersonalityScorePercentage>99</PersonalityScorePercentage>
              </PersonalityScorePercentageContainer>
              <PersonalityScoreTitle>Genuine</PersonalityScoreTitle>
            </PeronsalityScoreBar>
          </PersonalityScoreBarsContainer>
        </PersonalitySnapshotContainer>
      </Overlay>
    );
  }
}

export default PersonalitySnapshot;

const StyledLockIcon = styled(LockIcon)`
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
`;

const Overlay = styled.div`
  margin-top: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
`;

const LockedMessageContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  z-index: 1;
`;
const LockedMessage = styled.p`
  font-weight: bold;
  color: white;
  text-align: center;
`;
const CompleteProfileLink = styled.p`
  color: white;
  text-decoration: underline;
`;

const PersonalitySnapshotContainer = styled.div`
  background-color: white;
  padding: 20px;
  padding-bottom: 0px !important;
  margin-top: ${(props) => (props.overlay ? "0px" : "20px")}
  opacity: ${(props) => (props.overlay ? 0.3 : 1)};

`;

const PersonalitySnapshotTitleContainer = styled.div`
  border-bottom: 1px slid #f5f5f5;
`;
const PersonalitySnapshotTitle = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.75px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #FE832A;
`;
const PeronsalityScoreBar = styled.div`
  border: 1px solid #f0f0f0;
  padding: 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #999;
  margin-top: 20px;
`;
const PersonalityScoreTitle = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right:auto;
  font-size: 12px;
`;
const PersonalityScorePercentageContainer = styled.div`
  background: rgb(125,89,252);
  background: radial-gradient(circle, rgba(125,89,252,${(props) => props.opacity}) 0%, rgba(82,56,163,${(props) => props.opacity}) 100%);  border-radius: 100px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PersonalityScorePercentage = styled.p`
  margin: 0;
  color: white;
  font-size: 10px;
`;

const PersonalityDetailsContainer = styled.div`
  margin-top: 20px;
`;
const PersonalityDetail = styled.div``;
const PersonalityDetailHeading = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;
const PersonalityDetailDescription = styled.p`
  font-size: 12px;
  color: #999;
`;

const PersonalityScoreBarsContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
`;

const CultureReportContainer = styled.div`
  border-top: 1px solid #f5f5f5;
  cursor: pointer;
`;
const CultureReportHeading = styled.p`
  font-size: 14px;
  text-align: center;
  color: #FE832A;
  margin: 0;
  padding: 15px 0px;
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

const CultureReportHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;
const CultureReportGoGigLogo = styled.img`
  width: 160px;
`;
const CultureReportTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;
const CultureReportExit = styled.p`
  font-size: 30px;
  color: #999;
  cursor: pointer;
  margin: 0;
`;
const FullCultureReportContainer = styled.div`
  padding: 30px;
  padding-top: 0px;
  display: flex;
`;
const CultureReportSnapshotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  width: 600px;
`;
const CultureReportDataContainer = styled.div`
  flex: 1;
  padding: 40px;
  margin-left: 20px;
  border-left: 1px solid #f5f5f5;
`;
const PersonalitySnapshotViewContainer = styled.div`
  padding-left: 60px;
  font-size: 14px;
`;
const PersonalitySnapshotSubtitle = styled.p`
  font-weight: bold;
  margin: 0;
`;
const PersonalitySnapshotDescription = styled.p`
  color: #999;
`;
const CultureReportSnapshotTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
`;
const CultureReportCamera = styled.img`
  width: 30px;
  height: 30px;
`;
const CultureReportSnapshotTitle = styled.p`
  font-weight: bold;
  margin: 0;
  margin-left: 10px;
`;

const CultureReportDataTitle = styled.p`
  font-weight: bold;
`;

const DataContainer = styled.div`
  width: 100%;
`;

const DataContainerLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-right: 1px;
`;
const DataContainerLeftTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-right: 10px;
  width: 250px;
`;
const DataContainerLeftBarContainer = styled.div`
  width: ${(props) => props.percent}%;
  background-color: #d8d8d8;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 3px;
  margin: 2px;
`;
const DataContainerLeftBarPercent = styled.p`
  color: #000;
  margin: 0;
  text-align: center;
  font-size: 10px;
`;

const DataContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1px;
  justify-content: space-between;
`;
const DataContainerRightBarContainer = styled.div`
  width: ${(props) => props.percent}%;
  background-color: #d8d8d8;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 3px;
  margin: 2px;
`;
const DataContainerRightBarPercent = styled.p`
  color: #000;
  margin: 0;
  text-align: center;
  font-size: 10px;
`;
const DataContainerRightTitle = styled.p`
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-left: 10px;
  width: 250px;
  text-align: right;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopFiveTitle = styled.p`
  font-weight: bold;
`;

const TopFiveBubblesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const BubbleContainer = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: rgb(125,89,252);
  background: radial-gradient(circle, rgba(125,89,252,${(props) => props.opacity}) 0%, rgba(82,56,163,${(props) => props.opacity}) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 160px;
`;

const BubbleText = styled.p`
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
`;
const BubbleSubtitle = styled.p`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: #999;
`;

const BubbleScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MidLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const MidLineText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  width: 200px;
`;

const MidLineSpacer = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f5f5f5;
`;
