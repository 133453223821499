import React, { Component } from "react";
import API from "API";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

class ResetPasswordScreen extends Component {
  state = {
    email: "",
    repeatEmail: "",
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    API.noOptionsPost(`/UserResetPasswordCodes`, {
      email: this.state.email,
    }).then((response) => {
      this.props.history.push("/reset-password/thank-you");
    });
  };

  render() {
    return (
      <Container className="vh-100">
        <RegisterContainer>
          <RegisterContainerPane className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"></RegisterContainerPane>
          <RegisterContainerPane className="p-5">
            <h1>Reset Password</h1>
            <Form>
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Re-enter Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Repeat Email"
                  name="repeatEmail"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  Just type your email again to make sure we send the link to
                  the right place.
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Form>

            <Link to="/login">
              <p className="text-muted mt-3 text-center">
                Need to login? Go here.
              </p>
            </Link>
            <Link to="/signup">
              <p className="text-center text-muted">
                Don't have an account? Register here.
              </p>
            </Link>
          </RegisterContainerPane>
        </RegisterContainer>
      </Container>
    );
  }
}

export default ResetPasswordScreen;

const RegisterContainer = styled.div`
  background: white;
  max-width: 800px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 2;
  border-top: 10px solid #7D59FC;
`;

const StyledBanner = styled.img`
  width: 350px;
`;

const RegisterContainerPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeading = styled.h1`
  color: #0c3352;
  font-size: 48px !important;
  font-weight: bold;
`;

const StyledH3 = styled.h3`
  font-size: 18px;
  color: #000;
`;

const StyledLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  display: block;
  margin-bottom: 0;
`;
