import React from 'react'
import PropTypes from 'prop-types'

import data from './constants/gigavoltContent';
import RootLandingTemplate from './components/RootLandingTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingRoot(props) {
  const { gigavolt } = data; 
  return (
    <>
      <RootLandingTemplate
        {...gigavolt}
      />
      <TemplateFooter
        {...gigavolt}
      />
    </>
  )
}

LandingRoot.propTypes = {}

export default LandingRoot
