import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import mycarepathPrivacy from '../../constants/gigavoltPrivacyPolicy';

const useStyles = makeStyles({
  select: {
    width: '90%',
    padding: '8.5px 14px',
  },
  popoverRoot: {
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
  },
});

function RootBodyTemplate(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink1,
    contentHeroButtonLink2,
    recentlyAdded,
    contentBody,
    privacyForm,
    changePrivacyForm,
  } = props;
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [emailAddress, changeEmailAddress] = useState('');
  const [emailValidation, changeEmailValidation] = useState(false);
  const [phoneNumber, changePhoneNumber] = useState('');
  const [selectedOption, changeSelectedOption] = useState('Please select an option');
  const [openOtherOption, changeOpenOtherOption] = useState(false);
  const [otherOptionField, changeOtherOptionField] = useState('');
  const [confirmationForm, changeConfirmationForm] = useState(false);
  const styles = useStyles();

  const privacyContent = (
    <Grid
      container
      style={{
        minHeight: '100vh',
        backgroundColor: '#ccc',
      }}
    >
      <Grid
        item
        xs={1}
      />
      <Grid
        className={classes.privacyInnerContainer}
        item
        xs={10}
      >
        <div
          className={classes.privacyBackButton}
          onClick={() => handleChangePrivacyForm()}
        >
          <ArrowBackIcon />
          <Typography
            style={{
              marginLeft: '10px',
            }}
          >
            Back
          </Typography>
        </div>
        {mycarepathPrivacy()}
      </Grid>
    </Grid>
  );

  const bodyText = (
    <Grid
      className={classes.bodyContent}
      container
      style={{
        paddingBottom: '2rem',
        paddingTop: '4rem',
        backgroundColor: "#ffffff"
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          className={classes.bodyTitleContainer}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways the GIGAVOLT Platform Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          className={classes.infoContainer}
        >
          {/* <Grid
            item
            xs={0}
            lg={2}
          /> */}
          <Grid
            item
            container
            xs={12}
            lg={10}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.roundBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.roundBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Have real conversations with hiring companies that express interest in you —no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.roundBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.roundBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                  }}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={2}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
            style={{ paddingTop: '100px' }}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              {contentHeroTitle1}
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              {contentHeroTitle2}
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              {contentHeroDescription}
            </Typography>
            <Button
              className={classes.buttonOutlinePurple}
              href={contentHeroButtonLink1}
              target="_blank"
              rel="noreferrer"
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '55px',
                fontSize: 16
              }}
            >
              GET STARTED
            </Button>
          </Grid>

          <Grid
            item
            lg={1}
          />

          {/* <Grid
            item
            xs={4}
            lg={3}
            className={classes.formSignUpDesktop}
            style={{
              alignItems: 'flex-end',
              zIndex: '10',
            }}
          >
            { signUpForm }
          </Grid> */}

          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />

        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        className={classes.formSignUpMobile}
        style={{
          // margin: '20px 0',
          alignItems: 'flex-end',
          zIndex: '10',
        }}
      >
        { signUpForm }
      </Grid> */}
      {bodyText}
      <Grid
        container
        style={{
          padding: '2.5rem 0',
          backgroundColor: '#fff'
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '1.238rem',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              color: "#5238A3",
              textAlign: 'center'
            }}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSquareFilledRed}
            href={contentHeroButtonLink2}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '20px 0',
              minWidth: '200px',
              height: '55px',
              fontSize: 16
            }}
          >
            SIGN UP NOW
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {landingContent}
      {/* <Popover
        open={confirmationForm}
        onClose={handleFormClose}
        classes={{
          paper: styles.popoverRoot,
        }}
        style={{
          overflowY: 'scroll',
          height: '100%',
          width: '100%',
          background: 'rgba(0,0,0,0.2)',
          boxShadow: 'none',
        }}
        disableScrollLock
      >
        <div
          className={classes.popupContainer}
        >
          <Typography
            variant="h2"
            className={classes.popupTitle}
          >
            Thank you for signing up to speak with a GIGAVOLT Hiring Expert
          </Typography>
          <Typography
            style={{
              padding: '20px 0',
              fontSize: '1rem',
              fontFamily: 'Montserrat',
            }}
          >
            A member of our recruitment team will vet your submission to verify your identity and qualifications. Once we have completed this process, someone from GIGAVOLT will follow up with you within X business days. We look forward to connecting with you soon!
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 601px)': {
                justifyContent: 'center'
              }
            }}
          >
            <Button
              className={classes.buttonSquareFilledRed}
              onClick={() => handleFormClose()}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
              }}
            >
              Exit
            </Button>
          </div>
        </div>
      </Popover> */}
    </Grid>
  );
}

RootBodyTemplate.propTypes = {}

export default RootBodyTemplate
