import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';

import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import classes from './index.module.css';

import useMediaQuery from '../../utils/useMediaQuery';

function TemplateFooter(props) {

  const isMobile = useMediaQuery(768);
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={1}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        >
          <Link
            to="/privacy-policy"
            className={classes.footerLink}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LockIcon
              style={{
                fill: '#fff',
              }}
            />
            <Typography
              style={{
                color: '#fff',
                fontSize: '0.75rem',
                fontFamily: 'Montserrat',
                marginLeft: '5px',
                marginTop: '3px'
              }}
            >
              PRIVACY POLICY
            </Typography>
          </Link>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          lg={6}
          xs={12}
        >
          {isMobile && <>
            <a href="https://twitter.com/GigaVoltHub" target="_blank">
              <AiOutlineTwitter
                style={{
                  color: "#fff",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/gigavolthub" target="_blank">
              <AiFillLinkedin
                style={{
                  color: "#fff",
                  fontSize: 24,
                  fontWeight: "bold",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
            </a>
          </>}
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        >
          <Typography
            className={classes.footerGogigTypography}
          >
            POWERED BY
          </Typography>
          <a href="https://www.gogig.com">
            <img
              className={classes.footerGogigLogo}
              src="/icons/gogig-logo.svg"
              alt=""
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          lg={1}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;