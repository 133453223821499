import React, { Component } from "react";
import API from "API";
import styled from "styled-components";
import { Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as SpeakerIcon } from "images/icons/Feedback.svg";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment-timezone";
import scaleTwo from "images/logo/mycarelogo-white-2.png";

class UserFeed extends Component {
  state = {
    feed: [],
    profileComplete: false,
    profilePhoto: "",
    message: "",
  };

  componentDidMount() {
    API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
      (response) => {
        console.log("Feed Data: ", response.data);
        this.setState({
          feed: response.data,
        });
      }
    );

    // var myInt = setInterval(() => {
    //   API.get(`/UserProfiles/${this.props.userToken.userId}`).then(response => {
    //     this.setState({
    //       profileComplete: response.data.profileComplete
    //     });
    //   });
    // }, 2000);
    // this.setState({
    //   myInt: myInt
    // });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  componentDidUpdate() {
    // API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
    //   this.setState({
    //     feed: response.data,
    //     profileComplete: response.data.profileComplete,
    //   });
    // });
  }

  handleSubmit = () => {
    API.post(`/UserFeeds`, {
      message: this.state.message,
      attachment: this.state.profilePhoto,
      userId: this.props.userToken.userId,
    }).then(() => {
      this.setState({
        message: "",
        profilePhoto: "",
      });
      API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
        (response) => {
          this.setState({
            feed: response.data,
          });
        }
      );
    });
  };

  handleDateValidation = (cstDateString) => {
    const cstMoment = moment.tz(cstDateString, 'America/Chicago');
    const today = moment();

    // Compare the day, month, and year of the CST date and today's date
    if(cstMoment.date() <= today.date() && cstMoment.month() <= today.month() && cstMoment.year() <= today.year()) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearInterval(this.state.myInt);
  }

  renderStatusMessage() {
    return this.props.profileComplete ? (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SpeakerIcon />
          <CompleteProfileCardTitle>You're all set!</CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          You've succesfully completed your profile! Check your report
          under Communication Style Report Snapshot to learn more about how hiring
          managers will see you.
        </CompleteProfileCardDescription>
        <Row>
          <Col>
            <Link to="/profile">
              <Button className="float-right">View Profile</Button>
            </Link>
          </Col>
        </Row>
      </CompleteProfileCard>
    ) : (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SearchIcon />

          <CompleteProfileCardTitle>
            Your profile is not discoverable to Hiring Companies within
            GIGAVOLT
          </CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          Your profile is not complete. In order for you to become discoverable
          to Hiring Companies in GIGAVOLT and view your Professional
          Communication Style Report, you must complete your profile.
        </CompleteProfileCardDescription>
        <Link to="/profile?onboard=true">
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            View Profile
          </Button>
        </Link>
      </CompleteProfileCard>
    );
  }

  render() {
    return (
      <div>
        {!this.props.recruiterMode && this.renderStatusMessage()}

        <CompleteProfileCard style={{ marginTop: 20 }}>
          <CompleteProfileCardTitleContainer>
            <NotificationIcon />
            <CompleteProfileCardTitle>
              Enable Notifications
            </CompleteProfileCardTitle>
          </CompleteProfileCardTitleContainer>
          <CompleteProfileCardDescription>
            We don't communicate to you often, but when we do it is of value
            based on your desires for career advancement
          </CompleteProfileCardDescription>
          <Row>
            <Col>
              <Link to="/settings/notifications">
                <Button className="float-right">Go To Notifications</Button>
              </Link>
            </Col>
          </Row>
        </CompleteProfileCard>

        {/* {this.props.location.pathname !== "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "white",
              marginBottom: 20,
              padding: 15,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  placeholder="What would you like to post?"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                  }}
                ></textarea>
              </div>
              {this.state.profilePhoto === "" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <Dropzone onDrop={this.uploadFile}>
                    {({ getRootProps, getInputProps }) => (
                      <StyledSection>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{ margin: 0 }} className="text-muted">
                            Add Attachment
                          </p>
                        </div>
                      </StyledSection>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <div>
                    <p
                      onClick={() => this.setState({ profilePhoto: "" })}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      Remove
                    </p>
                  </div>
                  <ProfilePhoto src={this.state.profilePhoto} />
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  flex: "0 0 auto",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={this.handleSubmit} className="float-right">
                  Post
                </Button>
              </div>
            </div>
          </div>
        )} */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 1 at 1:14 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to the GIGAVOLT anonymous talent community! The GIGAVOLT platform was designed for the more experienced battery industry professional. Battery industry professionals have the ability to share their career experience and unique desires anonymously, which removes discrimination and implicit bias. As a result, Hiring Professionals can prioritize equality of opportunity in their hiring practices and connect with talent they would not find on traditional job board sites.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Why Now is the Perfect Time to Consider New Electrification Roles</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 7th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              The electric vehicle and battery tech sectors are exploding. Major investments are being made as societies transition to renewable energy. It's a revolution - and it needs talent.
              <br /> <br />
              That's why now is the ideal moment for professionals at any career stage to explore new electrification roles. With demand growing rapidly, opportunities are plentiful.
              <br /> <br />
              Whether you are a recent STEM grad, an auto industry pro looking to upskill, or an engineer seeking purpose-driven work - there are exciting new roles tailored to your abilities.
              <br /> <br />
              Don't just find any job. Discover your electrification calling. GigaVolt makes it easy to pivot your skills and passion into the emerging EV/battery ecosystems.
              <br /> <br />
              Take charge of the clean energy future. Supercharge your career now in this fast-moving, high-impact industry. The electric transition is here. Be part of it.
              <br /> <br />
              #electrification #EVcareers #cleanenergyjobs
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>New grads: Jumpstart your electrification career</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 5th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              As you start your career, finding the right path can feel daunting. GIGAVOLT is here to help focus your journey.
              <br /> <br />
              1️⃣ Profile Your Potential - Input your interests, abilities, education, and aspirations into your GigaVolt profile. This showcases you as a candidate.
              <br />
              2️⃣ Get Matched to Your Best Fit - Our advanced algorithms analyze your profile data to match you with opportunities perfectly tailored to your skills and desires.
              <br/>
              3️⃣ Land an Electrifying Job - Apply with ease to exciting entry-level roles that align with your unique career goals. Bring your potential to companies driving the electric revolution.
              <br /> <br />
              GIGAVOLT doesn't just help you find any job - we help you discover your electrification calling. Join today to kickstart your future.
              <br /> <br />
              #batteryjobs #engineeringcareers #EVs #electrification
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Where Battery Engineers Make the Most Money 💰</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>October 2nd | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              If you're exploring battery engineering roles, where you live can significantly impact your earning potential. Here are the top 5 highest-paying states for battery engineers in the U.S, according to ZipRecruiter:
              <ol
                style={{
                  marginTop: "10px"
                }}
              >
                <li>Washington - $129,283 average salary</li>
                <li>New York - $123,292</li>
                <li>Maryland - $120,110</li>
                <li>Virginia - $119,691</li>
                <li>California - $119,162</li>
              </ol>
              Washington tops the list with salaries 14% above the national average! However, cost of living is a key factor. While California pays well, expenses in cities like SF and LA are steep.
              <br />
              Maryland emerges as an attractive option - high salaries plus more affordable living.
              <br />
              No matter where you are, battery expertise pays off big as EV adoption accelerates. And remote work opportunities are expanding too. Ready to power up your earning potential? Kickstart your career journey at <a href="https://gigavolt.io/" target="_blank" norel="noreferrer">www.GIGAVOLT.io</a>!
              <br /><br />
              #batteryjobs #engineeringcareers #EVs #electrification
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Two emerging careers leading the EV revolution</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>September 30th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              As electric vehicles go mainstream, new career opportunities are accelerating across the electrification sector. Here are two roles leading the charge:
              <ol
                style={{
                  marginTop: "10px"
                }}
              >
                <li>
                  <strong>Battery Systems Engineer</strong> - These experts optimize battery design for factors like energy density, safety, lifespan, and cost. With demand rising for longer-range EVs, engineers who can stretch battery capabilities will be in high demand.
                </li>
                <li>
                  <strong>Charging Infrastructure Technician</strong> - Installing and maintaining EV charging equipment is a hands-on role that's crucial for widespread adoption. You'll ensure charging networks operate seamlessly and identify future infrastructure needs.
                </li>
              </ol>
              Kickstart your electrification career journey today on GIGAVOLT. Our matching tools connect you to emerging roles tailored to your skills and passions.
              <br /><br />
              #electrificationcareers #EVjobs
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Grants fuel growth in battery metals workforce</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>September 28th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Recently, The Doe Run, a notable battery metals supplier in Missouri, was awarded a $1.2 million grant aimed at enhancing the skill set of its workforce, a move aligning with the burgeoning battery industry. At GIGAVOLT, we are geared to:
              <ol>
                <li>
                  <strong>Keep You Informed:</strong> Stay updated with the latest trends, grants, and opportunities in the electrification and battery sector to ensure you're always a step ahead.
                </li>
                <li>
                  <strong>Bridge Connections:</strong> Forge valuable connections with pioneering companies like Doe Run, opening doors to a realm of career possibilities in the vibrant EV sector.
                </li>
                <li>
                  <strong>Fuel Your Career Path:</strong> Whether you're looking to hone your technical skills or explore new horizons in the battery metals landscape, we provide the platform for you to thrive and propel your career forward.
                </li>
              </ol>
              Join us at www.GIGAVOLT.io to explore a world of opportunities in the EV sector.
              <br /><br />
              #GIGAVOLT #CareerGrowth #EVIndustry
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <a
            style={{
              textDecoration: "none",
              color: "#000",
            }}
            href="https://www.mining.com/missouri-battery-metals-supplier-gets-1-2-million-job-retention-training-grant/"
            target="_blank"
            norel="noreferrer"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://www.mining.com/wp-content/uploads/2023/09/Missouri-automotive-plant-2048x1365.jpeg" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  Missouri battery metals supplier gets $1.2 million job retention training grant
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Doe Run employs over 1,200, predominantly in Southeast Missouri, supplying minerals and metals critical to the battery industry. Missouri’s lead battery industry ranks second in the nation for its statewide economic impact.
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Recent clashes reveal growing pains in auto industry's shift to EVs</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>September 25th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
            Recent clashes between Stellantis NV and the United Auto Workers (UAW) reveal growing pains as the auto industry speeds towards electrification. Job security and fair wages are hot topics.
            <br /><br />
            GIGAVOLT is here to bridge the gap, providing real-time industry insights and connecting professionals with tailored opportunities in the electrification realm. Stay updated, stay relevant, and ensure your career thrives in the electrification era with GIGAVOLT.
            <br /><br />
            Embark on your electrification career journey. Visit <a href="https://www.gigavolt.io/" target="_blank">www.GIGAVOLT.io</a> today.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <a
            style={{
              textDecoration: "none",
              color: "#000",
            }}
            href="https://www.bloomberg.com/news/articles/2023-09-22/uaw-stellantis-remain-at-odds-over-battery-jobs-pay-parity?leadSource=uverify%20wall"
            target="_blank"
            norel="noreferrer"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://assets.bwbx.io/images/users/iqjWHBFdfxIU/i_wy8EudvrrU/v1/2000x1334.jpg" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  UAW, Stellantis Remain at Odds Over Battery Jobs, Pay Parity
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Stellantis NV’s latest contract proposal to the United Auto Workers was met with resistance, with the union pushing back on the carmaker’s stance on job security in the electric-vehicle transition and pay parity, according to people familiar with the matter.
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard>

        <NewsFeedCard
          // style={{
          //   display: this.handleDateValidation("2023-08-30 10:00:00") ? "block" : "none",
          // }}
        >
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Everything you need to know about GIGAVOLT’s Communication Style Report</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 30th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Looking for an in-demand career? Here's a comparison of job recruitment times across different tech sectors:
              <br /> <br />
              🔋 Batteries: Average 56 days - 65% longer than the average recruitment time across all sectors.
              <br />
              🔗 Blockchain: Average 52 days - 21% above average
              <br />
              🔢 Quantum Computing: Average 47.8 days - No available data
              <br />
              🖨️ 3D Printing: Average 47 days - 35% above average
              <br />
              ⚖️ RegTech: Average 42 days - 9% above average
              <br /> <br />
              The data shows that roles in the battery industry are staying open significantly longer than in other sectors, hinting at a high demand for skilled workers.
              <br /> <br />
              The reason behind this trend? Companies are investing heavily in building domestic battery manufacturing plants, marking a great surge in job opportunities. Be part of this electric boom.
              <br /> <br />
              Charge up your career today at <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              <img
                style={{ width: "100%" }}
                src={require("images/feed/battery-jobs-statistics.png")}
              /><br/><br/>
              #GIGAVOLT #batterycareers #jobopportunity #industrygrowth
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Everything you need to know about GIGAVOLT’s Communication Style Report</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 28th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              The battery manufacturing industry is booming, promising thousands of new jobs across the United States. Here's a glimpse of the electrification wave:
              <br /> <br />
              <ol>
                <li>Panasonic has promised 4,000 jobs by 2025 in De Soto, Kansas, with a planned capacity of 30 GWh.</li>
                <li>Canoo is bringing 2,000 jobs to Pryor, Oklahoma by 2023 with a 3.2 GWh plan.</li>
                <li>Envision AESC | BMW is expected to create 2,000 jobs in Kentucky by 2025 with a 30 GWh plan.</li>
                <li>SK Innovation plans to generate 3,000 jobs in Georgia by 2023, with a capacity of 22 GWh.</li>
                <li>Hyundai has pledged to create 8,100 jobs in Georgia with the capacity to be announced.</li>
              </ol>
              ...and the list goes on.
              <br /> <br />
              These aren't just jobs; they are career opportunities. Opportunities to be a part of the green revolution. Opportunities to make a difference.
              <br /> <br />
              With GIGAVOLT, you don't just find a job - you shape the future of the electrification industry. Visit <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a> today and step into your next electrification career.
              <br /><br />
              <img
                style={{ width: "100%" }}
                src={require("images/feed/talent-manufacturing-jobs.png")}
              /><br/><br/>
              #GIGAVOLT #manufacturingjobs #electrificationcareers #jobcreation
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>The latest trends in electrification</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 25th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Want to stay updated with the latest news and trends in the electrification industry? Here are some of the most popular newsletters to follow:
              <br /> <br />
              <ol>
                <li>Intercalation Station Newsletter</li>
                <li>The Electric</li>
                <li>Techtricity Newsletter</li>
                <li>Better Batteries Newsletter</li>
                <li>Lithium Valle Newsletter</li>
                <li>Green Rocks Newsletter</li>
                <li>Electrode Newsletter</li>
              </ol>
              Stay informed, stay ahead. Check out these resources and keep your finger on the pulse of the electrification industry. And don't forget to explore opportunities on GIGAVOLT, your pathway to electrification careers. <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              #GIGAVOLT #electricindustry #careerjourney #industryupdates
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Learn the key traits you need to upskill or break into the battery industry</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 23rd | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Education and training are fundamental to advancing the battery and electrification industry. Our survey showed that 32 out of 56 respondents expressed the need to hire individuals with a university level education in battery material knowledge in the near future.
              <br /> <br />
              There's an identified need for:
              <ol>
                <li>Educational programs at the community and 4-year-college level.</li>
                <li>Training for first response, safety, electrical skills, O&M, and installation at the trade school and community college level or through internal training programs.</li>
              </ol>
              The future is bright for those seeking to upskill or break into the battery industry. As the demand for qualified individuals continues to rise, so does the opportunity to make a difference. Start your journey today with GIGAVOLT. <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              #GIGAVOLT #educationandtraining #careeropportunities #electrificationcareers
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Tips to craft the perfect career pitch on GIGAVOLT</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 21st | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Crafting an effective pitch on GIGAVOLT can be pivotal for your career trajectory. The goal? To encapsulate your professional persona in 150 words or less. Here are a few steps to help you polish that pitch and make it stand out:
              <br /> <br />
              <ol>
                <li><strong>Emphasize your Unique Value Proposition:</strong> What sets you apart from others in the field? It could be your skills, experiences, or a niche area of expertise. This is what you want to highlight.</li>
                <li><strong>Tell a Story:</strong> Personal experiences and anecdotes resonate. They help form an emotional connection and make your pitch memorable.</li>
                <li><strong>State Your Goals:</strong> Make it clear what you're looking for. Whether you seek a new role, wish to transition to another area of the industry, or are open to collaboration, specify your intent.</li>
                <li><strong>Certifications and Achievements:</strong> Don't shy away from celebrating your success. Certifications, awards, and significant achievements validate your expertise.</li>
                <li><strong>Revise and Refine:</strong> An excellent pitch is seldom perfect in the first draft. Review it, refine it, and seek feedback.</li>
              </ol>
              Remember, your pitch is more than just an introduction; it's a snapshot of your professional journey and a beacon for the opportunities you're seeking. Make it count. Start crafting your pitch today on <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              #GIGAVOLT #careerpitch #careerjourney #electrificationcareers
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Everything you need to know about GIGAVOLT’s Communication Style Report</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 18th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Imagine being able to showcase not just your skills and experiences, but also your unique communication style to potential employers. That's exactly what GIGAVOLT’s Communication Style Report enables.
              <br /> <br />
              <ol>
                <li><strong>Your Top 5 communication attributes:</strong> Understand and leverage your individual strengths in professional conversations.</li>
                <li><strong>Tornado of words:</strong> See where your communication style ranks among your peers. Knowledge is power; understanding these attributes can guide you in tailoring your approach, ensuring you're in sync with the positions you're aiming for.</li>
                <li><strong>Snapshot:</strong> A concise summary of your communication attributes - perfect for quick reviews.</li>
                <li><strong>Communication Rec:</strong> Empower hiring professionals to interact with you in a way that resonates with your communication preferences.</li>
              </ol>
              By understanding and highlighting your communication style, we aim to enhance your professional interactions and make them more fruitful. Jumpstart your career journey with GIGAVOLT today at <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>


        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>How GIGAVOLT's AI Works</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 16th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Curious how GIGAVOLT's AI-powered system can advance your career in the electrification industry? We break it down:
              <br /> <br />
              <ol>
                <li>Input your desires, skills, experiences, and aspirations into your profile.</li>
                <li>Our advanced algorithms analyze this data.</li>
                <li>You get matched with relevant opportunities tailored to your career desires.</li>
              </ol>
              Spend less time searching, more time thriving with GIGAVOLT.
              <br /><br />
              Check out how we can help you advance your career: <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              #GIGAVOLT #AI #careeradvancement
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Making a great first impression on GIGAVOLT</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 14th | 10:00am CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Stepping into GIGAVOLT for the first time? Your profile is your first impression, and we're here to help you make it count.
              <br /> <br />
              <ol>
                <li>Select an avatar that reflects your professional persona.</li>
                <li>Use the “Pitch” section to showcase your skills and experiences relevant to the battery and electrification industry.</li>
                <li>Update your profile regularly to keep it in sync with your professional development.</li>
              </ol>
              Your profile is your first impression. Make it count.
              <br /><br />
              Visit GIGAVOLT today to jumpstart your career journey: <a href="https://www.gigavolt.io/" color="#dca10d">www.GIGAVOLT.io</a>
              <br /><br />
              #GIGAVOLT #electrificationcareers #electrification
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 3rd | 1:14pm CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              🔋🗓️ Get ready, battery professionals! North America's biggest Battery Show is happening Sept. 12-14, 2023 in Novi, MI. <br /><br />

              This electrifying event is a must-attend for all battery professionals! It will bring together over 15,000 attendees, including engineers, business leaders, top-industry companies, and innovative thinkers. The goal? To discover groundbreaking products and create powerful solutions for the future.<br /><br />

              Here's why you can't miss it:<br /><br />
              👥 NETWORKING: Connect with 15,000+ battery manufacturing and automotive professionals and decision-makers who are eager to learn about your products and solutions.<br /><br />
              🧪 EXPO: Discover and demo the latest products, technologies, and solutions from more than 775 suppliers from the Advanced Battery and Electric & Hybrid Vehicles Industry.<br /><br />
              📚 EDUCATION: Gain access to 4 tracks of technical education spanning new methods of advanced battery design, latest technologies, battery manufacturing development, and newly added forecasts of the market supply chain and regulatory outlook.<br /><br />
              🗣️ SPEAKERS: Hear from 200+ expert speakers who will enlighten and inspire with their insights and experiences.<br /><br />
              Save the date and stay tuned for more updates!<br /><br />

              <img
                style={{ width: "100%" }}
                src={require("images/feed/mich-battery-show.png")}
              /><br/><br/>
              #TheBatteryShow #AdvancedBattery #ElectricVehicles #HybridVehicles #BatteryIndustry #FutureIsElectric

            </NewsFeedCardDescription>


          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{ borderRadius: 0 }} src={require("images/logo/gigavolt-icon-1.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 1st | 2:00pm CST</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              {/* <strong>Zip Recruiter Data</strong><br /><br /> */}

              Curious about the most frequently searched job titles for Battery Engineer jobs? According to a <a style={{ color: "#FE832A" }} target="_blank" href="https://www.ziprecruiter.com/Jobs/Battery-Engineer#intsrc=seo.fe.dirp.faq_section-1h">ZipRecruiter search</a>, they are… <br /><br />

              <ol>
                <li>Car Battery</li>
                <li>Battery Research Scientist</li>
                <li>Battery Recycling</li>
                <li>Battery Service Technician</li>
                <li>Battery Scientist</li>
                <li>Lithium Ion Battery Engineer</li>
                <li>Interstate Battery</li>
                <li>Battery Changer</li>
                <li>Aircraft Battery Technician</li>
                <li>Battery Technician</li>
              </ol>
              Looking to relocate? You’ll find the most opportunities in: <br /><br />
              <ol>
                <li>Tennessee 🏞️</li>
                <li>New York 🗽</li>
                <li>Minnesota 🌲</li>
                <li>California 🏖️</li>
                <li>Texas 🤠</li>
                <li>New Jersey 🌆</li>
                <li>Michigan 🚗</li>
                <li>Utah 🏔️</li>
                <li>Florida 🌴</li>
                <li>Colorado 🏔️</li>
              </ol>
              When it comes to earnings,  the average salary for Battery Engineer jobs hovers around $113,326/year. You'll find that 25% earn $96,000 or less, while 75% earn $126,500 or more, and the top 10% earn over $146,000.

              <img
                style={{ width: "100%" }}
                src={require("images/feed/zip-recruiter-stats.png")}
              />
              Now’s the time to power up your career in battery engineering. GigaVolt is here to help you jump-start your professional journey with expert insights, opportunities, and support 🔋💡. <br /><br />

              #BatteryEngineering #JobMarket #EngineeringJobs #SalaryInsights

            </NewsFeedCardDescription>


          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/mycarelogo-white-2.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>
                Admin
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>March 27 at 6:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Real people. Real careers. Check out <a href="https://mycarepath.org">GIGAVOLT.org</a> today!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
          <iframe width={"100%"} height={310} src={"https://www.youtube.com/embed/tRITBYTrW4U"} title="GIGAVOLT" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/mycarelogo-white-2.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Oregon's Governor Recognizes Direct Support Professionals</NewsFeedCardName>
              <NewsFeedCardJob>Shout Out to Oregon's DSPs</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 21 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
            Salute to another year of Oregon DSPs NEVER tiring of doing something good!
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/newpost.png")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>


        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage               style={{ width: "auto", borderRadius: 0 }}
 src={require("images/logo/cwlogo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Central Willamette Credit Union</NewsFeedCardName>
              <NewsFeedCardJob>Supporting you and your financial future</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Sept 4 at 9:00 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
             Central Willamette Credit Union is honored to support Oregon's Direct Support Pros for their unparalleled, and largely unsung, support to the I/DD community. In recognition of Direct Support Professionals Recognition Week 2022, Central Willamette Credit Union honors Oregon's Direct Support Pros for their unparalleled, and largely unsung, support to the I/DD community. Because of you, services in Oregon SOAR!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> 

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage               style={{ width: "auto", borderRadius: 0 }}
 src={require("images/logo/chemeketalogo.jpg")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chemeketa Community College</NewsFeedCardName>
              <NewsFeedCardJob>Associate of Applied Science Degree</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Sept 1 at 10:08 am</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
          Are you interested in earning a degree that will prepare you for work in support and leadership positions serving people with developmental disabilities?  Earn your Associate of Applied Science (Direct Support Professional Degree) from Chemeketa Community College.  Chemeketa will prepare you to work in both private and public agencies that work within communities to provide direct support and advocacy to their clients.  You will take courses that cover human development, leadership, and case management.  Apply today at <a href="https://go.chemeketa.edu/apply" target="_blank" >go.chemekta.edu/apply</a> or call 503-399-5058 for more information.
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/postimg.jpg")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}


        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/mycarelogo-white-2.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>Admin</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 20 at 1:08 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>ßß
          <NewsFeedCardDescriptionContainer>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/letsnotgettired.png")}
            />
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              style={{ width: "auto", borderRadius: 0 }}
              src={require("images/oregon-logo.jpg")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>GIGAVOLT</NewsFeedCardName>
              <NewsFeedCardJob>ODDS</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 5:16 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
          <NewsFeedCardDescription>
              Oregon is the name, DIVERSABILITY is our game!
            </NewsFeedCardDescription>
            <img
              style={{ width: "100%" }}
              src={require("images/feed/iwork.png")}
            />
      
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {this.state.feed.map((feedItem) => {
          return feedItem.attachment === "" ? (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>{" "}
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
            </NewsFeedCard>
          ) : (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {" "}
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {/* {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"} */}
                    {feedItem.user.hasOwnProperty("recruiterProfile") &&
                      "Hiring Partner"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
              <NewsFeedVideoContainer>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={feedItem.attachment}
                />
              </NewsFeedVideoContainer>
            </NewsFeedCard>
          );
        })}

        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chris Hodges</NewsFeedCardName>
              <NewsFeedCardJob>CEO at GIGAVOLT</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 4:18 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Hey, this is Chris from GIGAVOLT. I'm excited to have you join our
              platform to make the journey of finding the perfect job for you
              effortless, and simple. GIGAVOLT fights for you both in terms of
              privacy, and the best job for you. We hope you enjoy using GIGAVOLT.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 8 at 7:14 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>

          <a
            style={{
              textDecoration: "none",
              color: "#000"
            }}
            href="https://www.gogig.com/blog/"
            target="_blank"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://www.gogig.com/hubfs/Interview-questions-man.jpg" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  How 2020 Is Reshaping the Way We Find Jobs
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Whether there’s a lull in your particular job market or bias
                  within the workforce, there’s a long list of reasons you may
                  be having trouble finding your ideal position. Here are four
                  big factors that may be stopping you from landing the right
                  opportunity in 2020
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard> */}

        {/* <BrowserView>
          <NewsFeedCard>
            <BadgesContainer>
              <GoGigLogo src={require("images/logo/GoGig.jpg")} />
              <DownloadOnGoGig
                style={{
                  marginLeft: 30,
                  flexGrow: 1
                }}
              >
                Download GIGAVOLT
              </DownloadOnGoGig>
              <div>
                <GetOnApple src="https://selinko.com/site/wp-content/uploads/2017/10/itunes-app-store-logo-300x104.png" />
                <GetOnGoogle src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </div>
            </BadgesContainer>
          </NewsFeedCard>
        </BrowserView> */}

        <br />

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 1</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 2</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't worry about having to do all the hard work required with
              searching for your next position to move up. Use GIGAVOLT effectively
              to make opportunities come to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig2.mp4#t=40" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 3</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Because of the anonymous nature of GIGAVOLT, don't worry about your
              employers finding you. Your identity is completely anonymous.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig3.mp4#t=30" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 5</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't have any path to promotion? That's fine, GIGAVOLT will help you
              get to the next level by bringing opportunities directly to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig5.mp4#t=10.0" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GIGAVOLT
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 4</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our customer support is available all the time. If you have any
              issues with your profile, send our team an email at
              support@gogig.com
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig4.mp4#t=20" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}
      </div>
    );
  }
}

export default UserFeed;

const CompleteProfileCard = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoGigLogo = styled.img`
  width: 60px;
`;
const DownloadOnGoGig = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
`;
const GetOnApple = styled.img`
  height: 40px;
`;
const GetOnGoogle = styled.img`
  height: 60px;
`;

const NewsFeedBlogContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 20px;
  border: 1px solid #f5f5f5;
  align-items: center;
  :hover:after {
    position: absolute;
    content: "";
    background-color: rgba(0,0,0,0.05);
    height: 100%;
    width: 100%;
  }
`;
const NewsFeedBlogImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit: cover;
`;
const NewsFeedBlogMetaContainer = styled.div``;
const NewsFeedBlogHeadline = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedBlogDescription = styled.p`
  font-size: 12px;
`;

const CompleteProfileCardTitleContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CompleteProfileCardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  margin-left: 10px;
`;
const CompleteProfileCardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const CompleteProfileCardButton = styled.button``;

const NewsFeedCard = styled.div`
  background: white;
  margin-top: 20px;
  padding: 20px;
`;
const NewsFeedCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
`;

const NewsFeedCardImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 10px;
`;

const NewsFeedCardDetailsContainer = styled.div`
  flex: 3;
`;
const NewsFeedCardName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedCardJob = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardTime = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardDescriptionContainer = styled.div``;
const NewsFeedCardDescription = styled.p`
  color: #000;
  margin: 0;
  font-size: 14px;
  padding: 10px;
`;

const NewsFeedVideoContainer = styled.div`
  video {
    width: 100%;
  }
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  text-align: center;
  height: 100px;
  border-radius: 8px;
`;

const ProfilePhoto = styled.img`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
`;
