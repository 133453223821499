import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import useMediaQuery from '../../utils/useMediaQuery';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;
  const [navbarOpacity, changeNavbarOpacity] = useState('rgba(35, 2, 79, 0)');
  const [navSocials, changeNavSocials] = useState('block');
  const [navSignIn, changeNavSignIn] = useState('none');
  const [navThreshold, changeNavThreshold] = useState(false);
  const pathname = window.location.pathname.replace('/', '');



  const onPageRender = () => {
    const initialPageValue = 0 + 100;
    const onScroll = () => {
      const originalScrollPosition = window.scrollY;
      const scrollPosition = window.scrollY + window.innerHeight / 8;
      // Initial Fade In
      if (initialPageValue > originalScrollPosition) {
        const scrollPercent = ((originalScrollPosition - 0) * 100) / (initialPageValue - 0);
        const navbarFadeIn = (1 * scrollPercent) / 100;
        changeNavbarOpacity(`rgba(35, 2, 79, ${navbarFadeIn})`);
        changeNavSocials('block');
        changeNavSignIn('none');
        changeNavThreshold(false);
      }
      // Navbar Shows completely
      if (initialPageValue < originalScrollPosition) {
        changeNavbarOpacity('rgba(35, 2, 79, 1)');
        changeNavSocials('none');
        changeNavSignIn('block');
        changeNavThreshold(true);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  };

  useEffect(() => {
    onPageRender();
  }, []);

  const isMobile = useMediaQuery(768);

  return (
    <nav
      className={classes.navContainer}
      style={{
        background: navbarOpacity,
      }}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={1}
        />
        <Grid
          className={classes.navFullHeight}
          item
          xs={12}
          md={3}
        >
          <Link
            to="/"
          >
            <img
              className={clsx(classes.logo, classes.desktopPadding)}
              src={desktopLogo}
              alt=""
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={0}
          md={4}
        />
        <Grid
          item
          xs={0}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          { !isMobile && <>
          <a href="https://twitter.com/GigaVoltHub" target="_blank">
            <AiOutlineTwitter
              style={{
                color: "#7D59FC",
                fontSize: 24,
                fontWeight: "bold",
                marginLeft: 10,
                marginRight: 10,
              }}
            />
          </a>
          <a href="https://www.linkedin.com/company/gigavolthub" target="_blank">
            <AiFillLinkedin
              style={{
                color: "#7D59FC",
                fontSize: 24,
                fontWeight: "bold",
                marginLeft: 10,
                marginRight: 10,
              }}
            />
          </a>
          </>}
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;